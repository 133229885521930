/*** IMPORTS FROM imports-loader ***/
import { hns } from "../../../../../../workspace/cms-free-branding/cms-free-branding-lib/static/hns-shim.js";

hns('I18n.translations.tl', {
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "₱"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": true
    },
    "percentage": {
      "format": {
        "delimiter": ",",
        "format": "%n%",
        "precision": 0
      }
    },
    "human": {
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": "byte",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "tb": "TB"
        }
      },
      "array": {
        "inclusive": {
          "wordsConnector": ", ",
          "twoWordsConnector": " at ",
          "lastWordConnector": ", at "
        },
        "exclusive": {
          "wordsConnector": ", ",
          "twoWordsConnector": " o ",
          "lastWordConnector": ", o "
        }
      }
    }
  }
});

