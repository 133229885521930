/*** IMPORTS FROM imports-loader ***/
import { hns } from "../../../../../../workspace/cms-free-branding/cms-free-branding-lib/static/hns-shim.js";

hns('I18n.translations.sk', {
  "number": {
    "currency": {
      "format": {
        "delimiter": " ",
        "format": "%n %u",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      }
    },
    "format": {
      "delimiter": " ",
      "precision": 3,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": true
    },
    "percentage": {
      "format": {
        "delimiter": " ",
        "format": "%n%",
        "precision": 0
      }
    },
    "human": {
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "B",
            "other": "B"
          },
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "tb": "TB"
        }
      },
      "array": {
        "inclusive": {
          "wordsConnector": ", ",
          "twoWordsConnector": " a  ",
          "lastWordConnector": " a  "
        },
        "exclusive": {
          "wordsConnector": ", ",
          "twoWordsConnector": " or ",
          "lastWordConnector": ", or "
        }
      }
    }
  }
});

