export let Flow;
(function (Flow) {
  Flow["Crm"] = "crm";
  Flow["FbCrm"] = "fb-crm";
  Flow["Marketing"] = "marketing";
  Flow["Integrations"] = "integrations";
  Flow["Academy"] = "academy";
  Flow["Sales"] = "sales";
  Flow["Service"] = "service";
  Flow["ClassroomTraining"] = "classroom-training";
  Flow["Purchase"] = "purchase";
  Flow["AssetProvider"] = "asset-provider";
  Flow["Developers"] = "developers";
  Flow["CmsDevelopers"] = "cms-developers";
  Flow["HubspotForStartups"] = "hubspot-for-startups";
  Flow["SolutionsProvider"] = "solutions-provider";
  Flow["DirectoryListing"] = "directory-listing";
  Flow["HubSpotPartners"] = "hubspot-partners";
  Flow["Trial"] = "trial";
  Flow["StandaloneCmsPartner"] = "standalone-cms-partner";
  Flow["CmsFree"] = "cms-free";
  Flow["Connect"] = "connect";
  Flow["AcademyEmbedded"] = "academy-embedded";
  Flow["Microapp"] = "microapp";
  Flow["Commerce"] = "commerce";
  Flow["EngageAI"] = "engage-ai";
})(Flow || (Flow = {}));