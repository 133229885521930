/*** IMPORTS FROM imports-loader ***/
import { hns } from "../../../../../../workspace/cms-free-branding/cms-free-branding-lib/static/hns-shim.js";

hns('I18n.translations.pl', {
  "number": {
    "currency": {
      "format": {
        "delimiter": " ",
        "format": "%u%n",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": true
      }
    },
    "format": {
      "delimiter": " ",
      "precision": 3,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": true
    },
    "percentage": {
      "format": {
        "delimiter": " ",
        "format": "%n%",
        "precision": 0
      }
    },
    "human": {
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "bajt",
            "few": "bajty",
            "many": "bajtów",
            "other": "bajty"
          },
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "tb": "TB"
        }
      },
      "array": {
        "inclusive": {
          "wordsConnector": ", ",
          "twoWordsConnector": " i ",
          "lastWordConnector": " i "
        },
        "exclusive": {
          "wordsConnector": ", ",
          "twoWordsConnector": " lub ",
          "lastWordConnector": " lub "
        }
      },
      "abbreviated": {
        "long": {
          "1000": {
            "one": "0 tysiąc",
            "few": "0 tysiące",
            "many": "0 tysięcy",
            "other": "0 tysiąca"
          },
          "10000": {
            "one": "00 tysiąc",
            "few": "00 tysiące",
            "many": "00 tysięcy",
            "other": "00 tysiąca"
          },
          "100000": {
            "one": "000 tysiąc",
            "few": "000 tysiące",
            "many": "000 tysięcy",
            "other": "000 tysiąca"
          },
          "1000000": {
            "one": "0 milion",
            "few": "0 miliony",
            "many": "0 milionów",
            "other": "0 miliona"
          },
          "10000000": {
            "one": "00 milion",
            "few": "00 miliony",
            "many": "00 milionów",
            "other": "00 miliona"
          },
          "100000000": {
            "one": "000 milion",
            "few": "000 miliony",
            "many": "000 milionów",
            "other": "000 miliona"
          },
          "1000000000": {
            "one": "0 miliard",
            "few": "0 miliardy",
            "many": "0 miliardów",
            "other": "0 miliarda"
          },
          "10000000000": {
            "one": "00 miliard",
            "few": "00 miliardy",
            "many": "00 miliardów",
            "other": "00 miliarda"
          },
          "100000000000": {
            "one": "000 miliard",
            "few": "000 miliardy",
            "many": "000 miliardów",
            "other": "000 miliarda"
          },
          "1000000000000": {
            "one": "0 bilion",
            "few": "0 biliony",
            "many": "0 bilionów",
            "other": "0 biliona"
          },
          "10000000000000": {
            "one": "00 bilion",
            "few": "00 biliony",
            "many": "00 bilionów",
            "other": "00 biliona"
          },
          "100000000000000": {
            "one": "000 bilion",
            "few": "000 biliony",
            "many": "000 bilionów",
            "other": "000 biliona"
          }
        },
        "short": {
          "1000": {
            "one": "0 tys.",
            "few": "0 tys.",
            "many": "0 tys.",
            "other": "0 tys."
          },
          "10000": {
            "one": "00 tys.",
            "few": "00 tys.",
            "many": "00 tys.",
            "other": "00 tys."
          },
          "100000": {
            "one": "000 tys.",
            "few": "000 tys.",
            "many": "000 tys.",
            "other": "000 tys."
          },
          "1000000": {
            "one": "0 mln",
            "few": "0 mln",
            "many": "0 mln",
            "other": "0 mln"
          },
          "10000000": {
            "one": "00 mln",
            "few": "00 mln",
            "many": "00 mln",
            "other": "00 mln"
          },
          "100000000": {
            "one": "000 mln",
            "few": "000 mln",
            "many": "000 mln",
            "other": "000 mln"
          },
          "1000000000": {
            "one": "0 mld",
            "few": "0 mld",
            "many": "0 mld",
            "other": "0 mld"
          },
          "10000000000": {
            "one": "00 mld",
            "few": "00 mld",
            "many": "00 mld",
            "other": "00 mld"
          },
          "100000000000": {
            "one": "000 mld",
            "few": "000 mld",
            "many": "000 mld",
            "other": "000 mld"
          },
          "1000000000000": {
            "one": "0 bln",
            "few": "0 bln",
            "many": "0 bln",
            "other": "0 bln"
          },
          "10000000000000": {
            "one": "00 bln",
            "few": "00 bln",
            "many": "00 bln",
            "other": "00 bln"
          },
          "100000000000000": {
            "one": "000 bln",
            "few": "000 bln",
            "many": "000 bln",
            "other": "000 bln"
          }
        }
      }
    }
  }
});

