'use es6';

import spacename from 'I18n/init/internal/spacename';
import I18n from 'I18n';
const prefix = 'I18n.translations.';
export function hns(target, source) {
  if (!target.startsWith(prefix)) {
    throw new Error('unexpected target in hns shim');
  }
  const lang = target.substring(prefix.length);
  spacename(I18n.translations, {
    [lang]: source
  });
}